import _ from "lodash";

export const arraysAreEqual = (arr1 = [], arr2 = []) => {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays by name
  const sortedArr1 = [...arr1]?.sort((a, b) => a?.name.localeCompare(b?.name));
  const sortedArr2 = [...arr2]?.sort((a, b) => a?.name.localeCompare(b?.name));

  // Compare sorted arrays element by element
  return sortedArr1?.every((item, index) => {
    const item2 = sortedArr2[index];
    return item?.name === item2?.name && item?.id === item2?.id;
  });
};

export const isArrayEqual = (x, y) => {
  const sortedX = x.sort((a, b) => (a?.identifier > b?.identifier ? 1 : -1));
  const sortedY = y.sort((a, b) => (a?.identifier > b?.identifier ? 1 : -1));
  return _.isEqual(sortedX, sortedY);
};
