import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Box, Typography } from "@material-ui/core";
import FormHelperText from "@mui/material/FormHelperText";
//
import Editor from "../../components/Editor";
import { useStyles } from "./styles";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------

export default function RichTextField({
  name,
  label,
  isrequired = false,
  helperText,
  ...other
}) {
  const classes = useStyles();
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name] === "<p><br></p>") {
      setValue(name, "", {
        shouldValidate: !isSubmitSuccessful,
      });
    }
  }, [isSubmitSuccessful, name, setValue, values]);

  return (
    <Box>
      {/* Label Section */}
      {label && (
        <Box>
          <Typography className={classes.header}>{label}</Typography>
          {isrequired && <Typography className={classes.require}>*</Typography>}
        </Box>
      )}

      {/* Editor Field with Controller */}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Editor
            id={name}
            value={field.value}
            onChange={field.onChange}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )}
      />
    </Box>
  );
}

RichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isrequired: PropTypes.bool,
  helperText: PropTypes.string,
  other: PropTypes.object,
};
