import { setDateToUTCNoon } from "./date";

export const fetchExportConfig = async (query, clientId) => {
  try {
    const { data } = await query({
      variables: {
        clientId: clientId,
      },
      skip: !clientId,
    });
    return data?.exportConfig;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Validates the given service request for ORU export.
 *
 * @param {Object} serviceRequest - The service request to validate.
 * @param {string} serviceRequest.mrn - The medical record number of the patient.
 * @param {string} serviceRequest.lastName - The last name of the patient.
 * @param {string} serviceRequest.firstName - The first name of the patient.
 * @param {string} serviceRequest.dob - The date of birth of the patient (format: YYYY-MM-DD).
 * @param {string} serviceRequest.gender - The gender of the patient.
 * @param {string} serviceRequest.name - The name of the service request.
 * @throws {Error} If the service request is missing required data.
 * @throws {Error} If the patient's date of birth is in an incorrect format.
 */
export const validateExportORU = (serviceRequest) => {
  if (
    !serviceRequest?.mrn ||
    !serviceRequest?.lastName ||
    !serviceRequest?.firstName ||
    !serviceRequest?.dob ||
    !serviceRequest?.gender ||
    !serviceRequest?.name ||
    !serviceRequest?.accountNumber
  ) {
    throw new Error(`Failed to export. Service request is missing data.`);
  }

  if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(serviceRequest?.dob)) {
    throw new Error(`Failed to export. Patient DOB is incorrect format.`);
  }
};

/**
 * Hydrates the ORU export resource with the given parameters.
 *
 * @param {Object} params - The parameters for the export resource.
 * @param {Object} params.selectedDocument - The selected document.
 * @param {Object} params.serviceRequest - The service request details.
 * @param {Object} params.currentPatientsPool - The current patients pool.
 * @param {boolean} [params.isDefaultServiceRequestType=true] - Indicates if it is the default service request type.
 * @param {string|null} [params.selectedNewDocType=null] - The selected new document type.
 * @returns {Object} The hydrated ORU export resource.
 */
export const hydratedOruExportResource = ({
  selectedDocument,
  serviceRequest,
  currentPatientsPool,
  isDefaultServiceRequestType = true,
  selectedNewDocType = null,
}) => {
  const documentName =
    selectedNewDocType ??
    selectedDocument?.tags.find((tag) =>
      tag.key.includes("CaptureDocumentTags:documentType")
    ).value;
  const documentDescription =
    selectedDocument?.tags.find((tag) =>
      tag?.key?.includes("CaptureDocumentTags:documentDescription")
    )?.value || "No description";
  const departments = serviceRequest?.departments?.map((dept) => dept?.name);
  const srCodes = serviceRequest?.tenantServices?.map((code) => code?.name);

  return {
    documentOverrides: {
      documentDescription,
      documentName,
      id: selectedDocument?.uuid,
    },
    documents: [selectedDocument?.uuid],
    patient: {
      patientPoolId: currentPatientsPool?.id,
      mrn: serviceRequest?.mrn,
      lastName: serviceRequest?.lastName,
      firstName: serviceRequest?.firstName,
      middleName: serviceRequest?.middleName,
      suffix: serviceRequest?.suffix,
      dob: serviceRequest?.dob,
      gender: serviceRequest?.gender,
    },
    encounter: {
      accountNumber: serviceRequest?.accountNumber,
      name: serviceRequest?.name,
      departmentNames: departments,
    },
    serviceCodes: srCodes,
    providerNPI: serviceRequest?.orderingProvider?.npi,
    ...(!isDefaultServiceRequestType && {
      order: {
        resultDate: setDateToUTCNoon(serviceRequest?.resultDate),
        serviceDate: setDateToUTCNoon(serviceRequest?.serviceDate),
      },
    }),
  };
};
