import React, { Suspense, lazy } from "react";
import AuthenticatedRoute from "../AuthenticatedRoute";
import { Switch, Route } from "react-router-dom";
import Home from "../../pages/Home";
import Logout from "../../pages/Logout";
import RouterBoundary from "./RouterBoundary";
import { LinearProgress, Grid, Typography } from "@material-ui/core";
import Dialogs from "../../components/Dialogs";
const Referrals = lazy(() => import("../../pages/Referrals"));
const ReferralRequests = lazy(() => import("../../pages/ReferralRequests"));
const NewReferralSearch = lazy(() => import("../../pages/NewReferralSearch"));
const NewPdfTron = lazy(() => import("../../pages/NewPdfTron"));
const NewElasticDocument = lazy(() => import("../../pages/NewElasticDocument"));
const NewPatientSearch = lazy(() => import("../../pages/NewPatientSearch"));
const ServiceRequests = lazy(() => import("../../pages/ServiceRequests"));
const InsurancePlans = lazy(() => import("../../pages/Admin/InsurancePlans"));
const UserProvisioning = lazy(() =>
  import("../../pages/Admin/UserProvisioning")
);
const ServiceRequestCustomization = lazy(() =>
  import("../../pages/Admin/ServiceRequestCustomization")
);
const StatusCustomization = lazy(() =>
  import("../../pages/Admin/StatusCustomization")
);

const NotificationsCustomization = lazy(() =>
  import("../../pages/Admin/NotificationsCustomization")
);

const Totp = lazy(() => import("../../pages/Totp"));
const PracticeProviderSearch = lazy(() =>
  import("../../pages/PracticeProviderSearch")
);
const QsDashboard = lazy(() => import("../../pages/QsDashbaord"));

export const styles = (theme) => ({
  content: {
    height: "100vh",
  },
});

export class Routes extends React.Component {
  render() {
    const { authenticated, classes } = this.props;
    return (
      <main className={classes.content}>
        <Dialogs />
        <RouterBoundary>
          <Suspense
            fallback={
              <React.Fragment>
                <LinearProgress />
                <Grid container direction="column" alignItems="center">
                  <Typography variant="overline">Loading...</Typography>
                </Grid>
              </React.Fragment>
            }
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/exchange" component={Home} />
              <Route exact path="/logout" component={Logout} />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/referrals/sent"
                component={Referrals}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/referrals/received"
                component={ReferralRequests}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/reports"
                component={QsDashboard}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/patients/search"
                component={NewPatientSearch}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/documents"
                component={NewElasticDocument}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/referral-documents/:slug"
                component={NewPdfTron}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/configure-multifactor-login"
                component={Totp}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/referrals/search-facets"
                component={NewReferralSearch}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/providers/search"
                component={PracticeProviderSearch}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/service-requests/:id?"
                component={ServiceRequests}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/admin/hermes-tenant-admin-ui"
                component={InsurancePlans}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/admin/hermes-tenant-admin-ui-user-provisioning"
                component={UserProvisioning}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/admin/hermes-tenant-admin-sr-customization"
                component={ServiceRequestCustomization}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/admin/status-customization"
                component={StatusCustomization}
              />
              <AuthenticatedRoute
                authenticated={authenticated}
                path="/admin/notifications-customization"
                component={NotificationsCustomization}
              />
            </Switch>
          </Suspense>
        </RouterBoundary>
      </main>
    );
  }
}
