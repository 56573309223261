import { gql } from "@apollo/client";

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument(
    $clientId: Int
    $filename: String
    $isTemporary: Boolean
    $documentId: String
    $mimetype: String
    $fileSize: Int
    $additionalInfo: JSON
    $documentNotesCount: Int
  ) {
    updateDocument(
      clientId: $clientId
      filename: $filename
      documentId: $documentId
      mimetype: $mimetype
      fileSize: $fileSize
      additionalInfo: $additionalInfo
      isTemporary: $isTemporary
      documentNotesCount: $documentNotesCount
    ) {
      additionalInfo
      uuid
      documentNotesCount
      preSignedUrl
      createTime
      createdByUserId
      createdByUser {
        firstName
        lastName
      }
      filename
      fileSize
      mimetype
      tags {
        key
        value
      }
      temporary
    }
  }
`;

export const UPDATE_DOCUMENT_META = gql`
  mutation UpdateDocument(
    $clientId: Int
    $documentId: String
    $isTemporary: Boolean
  ) {
    updateDocument(
      clientId: $clientId
      documentId: $documentId
      isTemporary: $isTemporary
    ) {
      uuid
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $clientId: Int
    $filename: String
    $mimetype: String
    $fileSize: Int
    $cloneTagsFromDocumentId: String
    $additionalInfo: JSON
  ) {
    createDocument(
      clientId: $clientId
      filename: $filename
      mimetype: $mimetype
      fileSize: $fileSize
      additionalInfo: $additionalInfo
      cloneTagsFromDocumentId: $cloneTagsFromDocumentId
    ) {
      uuid
      clientId
      additionalInfo
      createTime
      createdByUserId
      createdByUser {
        firstName
        lastName
      }
      fileSize
      numPages
      filename
      mimetype
      tags {
        key
        value
      }
      preSignedUrl
      temporary
      versions {
        versionId
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument(
    $softDelete: Boolean
    $documentId: String
    $clientId: Int
  ) {
    deleteDocument(
      softDelete: $softDelete
      documentId: $documentId
      clientId: $clientId
    )
  }
`;

export const RESTORE_DOCUMENT = gql`
  mutation RestoreDocumentVersion(
    $documentId: String
    $version: String
    $clientId: Int
  ) {
    restoreDocumentVersion(
      documentId: $documentId
      version: $version
      clientId: $clientId
    ) {
      uuid
    }
  }
`;

export const UNDELETE_DOCUMENT = gql`
  mutation undeleteDocument($documentId: String, $clientId: Int) {
    undeleteDocument(documentId: $documentId, clientId: $clientId) {
      uuid
      deleted
    }
  }
`;

export const SEND_FAX_MUTATION = gql`
  mutation SendFaxDocument(
    $clientId: Int!
    $documentId: String!
    $faxDocumentInput: FaxDocumentInput!
    $pages: String
    $includeCoverPage: Boolean
  ) {
    sendFaxDocument(
      clientId: $clientId
      documentId: $documentId
      faxDocumentInput: $faxDocumentInput
      pages: $pages
      includeCoverPage: $includeCoverPage
    ) {
      clientId
      fromUserId
      id
      recipientFaxNumber
      recipientName
      referenceId
      textMessage
    }
  }
`;

export const EXPORT_DOC = gql`
  mutation Mutation($clientId: Int!, $exportResource: ExportInput!) {
    export(clientId: $clientId, exportResource: $exportResource) {
      clientId
      exportType
      documents
    }
  }
`;
