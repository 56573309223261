export const determineSideMenuOptions = (
  authenticated,
  roles,
  { openReportDropdown: reportState, setOpenReportDropdown: reportOnClick },
  { openAdminDropdown: adminState, setOpenAdminDropdown: adminOnClick },
  captureMode,
  exchangeMode,
  dashboardLists,
  networkDashboardOnlyMode
) => {
  const options = [];
  options.push({ id: "menu_home", text: "Home", route: "/" });

  if (authenticated) {
    if (!captureMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_sent_referrals",
        text: "Sent Referrals",
        route: "/referrals/sent",
      });
    }
    if (!captureMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_received_referrals",
        text: "Received Referrals",
        route: "/referrals/received",
      });
    }
    if (!captureMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_search_referrals_facets",
        text: "Search Referrals",
        route: "/referrals/search-facets",
      });
    }
    if (!captureMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_search_patients",
        text: "Patients",
        route: "/patients/search",
      });
    }
    if (!captureMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_search_practice_providers",
        text: "Practice/Providers",
        route: "/providers/search",
      });
    }
    if (!networkDashboardOnlyMode) {
      options.push({
        id: "menu_document_triage",
        text: "Document Triage",
        route: "/documents",
      });
    }
    if (!exchangeMode && !networkDashboardOnlyMode) {
      options.push({
        id: "menu_service_request",
        text: "Service Requests",
        route: "/service-requests/",
      });
    }
    if (dashboardLists?.length > 0) {
      const option = {
        id: "menu_analytics",
        text: "Analytics",
        nested: true,
        list: [],
        stateVariable: reportState,
        onClickFn: reportOnClick,
      };
      if (dashboardLists) {
        if (networkDashboardOnlyMode) {
          dashboardLists
            .filter((dashboard) => !dashboard?.isMemberOnly)
            .forEach((dashboard) => {
              option.list.push({
                id: dashboard.qsDashboardId,
                text: dashboard.name,
                route: `/reports/${dashboard.id}`,
              });
            });
        } else {
          dashboardLists.forEach((dashboard) => {
            option.list.push({
              id: dashboard.qsDashboardId,
              text: dashboard.name,
              route: `/reports/${dashboard.id}`,
            });
          });
        }
      }
      options.push(option);
    }
    if (roles?.adminTab && !networkDashboardOnlyMode) {
      const option = {
        id: "menu_admin",
        text: "Admin",
        nested: true,
        list: [
          {
            id: "insurance_plans",
            text: "Insurance Plans",
            route: "/admin/hermes-tenant-admin-ui",
          },
          {
            id: "user_provisioning",
            text: "User Provisioning",
            route: "/admin/hermes-tenant-admin-ui-user-provisioning",
          },
          {
            id: "status_customization",
            text: "Status Customization",
            route: "/admin/status-customization",
          },
          {
            id: "notifications_customization",
            text: "Notifications Customization",
            route: "/admin/notifications-customization",
          },
          {
            id: "sr_name_customization",
            text: "Service Request Names",
            route: "/admin/hermes-tenant-admin-sr-customization",
          },
        ],
        stateVariable: adminState,
        onClickFn: adminOnClick,
      };
      options.push(option);
    }
  }
  return options;
};
