import { gql } from "@apollo/client";

export const CREATE_SERVICE_REQUEST = gql`
  mutation CreateServiceRequest(
    $name: String!
    $orderingProvider: ProviderLookupInput
    $associatedDocuments: [String]!
    $patientId: Int!
    $accountNumber: String
    $clientId: Int
    $priority: ServiceRequestPriority
    $departmentIds: [String]
    $tenantServiceIds: [String]
    $serviceDate: DateTime
    $resultDate: DateTime
  ) {
    createServiceRequest(
      name: $name
      orderingProvider: $orderingProvider
      associatedDocuments: $associatedDocuments
      patientId: $patientId
      accountNumber: $accountNumber
      clientId: $clientId
      priority: $priority
      departmentIds: $departmentIds
      tenantServiceIds: $tenantServiceIds
      serviceDate: $serviceDate
      resultDate: $resultDate
    ) {
      id
    }
  }
`;

export const UPDATE_SERVICE_REQUEST = gql`
  mutation UpdateServiceRequest(
    $id: String!
    $name: String
    $associatedDocuments: [String]
    $patientId: Int
    $accountNumber: String
    $clientId: Int
    $priority: ServiceRequestPriority
    $orderingProvider: ProviderLookupInput
    $departmentIds: [String]
    $tenantServiceIds: [String]
    $serviceDate: DateTime
    $resultDate: DateTime
  ) {
    updateServiceRequest(
      id: $id
      name: $name
      associatedDocuments: $associatedDocuments
      patientId: $patientId
      accountNumber: $accountNumber
      clientId: $clientId
      priority: $priority
      orderingProvider: $orderingProvider
      departmentIds: $departmentIds
      tenantServiceIds: $tenantServiceIds
      serviceDate: $serviceDate
      resultDate: $resultDate
    ) {
      id
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_STATUS = gql`
  mutation updateServiceRequestStatus(
    $updateServiceRequestStatusId: String!
    $status: String!
    $clientId: Int
  ) {
    updateServiceRequestStatus(
      id: $updateServiceRequestStatusId
      status: $status
      clientId: $clientId
    ) {
      id
      statusLastUpdatedTime
      status
      statusLabel
      appointmentTime
      appointmentProvider
      appointmentLocation
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_APPOINTMENT = gql`
  mutation UpdateServiceRequestAppointment(
    $updateServiceRequestAppointmentId: String!
    $appointmentTime: DateTime
    $appointmentLocation: String
    $appointmentProvider: String
    $clientId: Int
  ) {
    updateServiceRequestAppointment(
      id: $updateServiceRequestAppointmentId
      appointmentTime: $appointmentTime
      appointmentLocation: $appointmentLocation
      appointmentProvider: $appointmentProvider
      clientId: $clientId
    ) {
      appointmentTime
      appointmentLocation
      appointmentProvider
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_NOTES = gql`
  mutation AddNoteToServiceRequest(
    $addNoteToServiceRequestId: String!
    $note: String!
    $clientId: Int
  ) {
    addNoteToServiceRequest(
      id: $addNoteToServiceRequestId
      note: $note
      clientId: $clientId
    ) {
      id
      notes {
        user {
          firstName
          lastName
        }
        createTime
        text
      }
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_STATUSES_CONFIG = gql`
  mutation UpdateServiceRequestStatusConfig(
    $clientId: Int!
    $statusConfig: [ServiceRequestStatusInput]!
  ) {
    updateServiceRequestStatusConfig(
      clientId: $clientId
      statusConfig: $statusConfig
    ) {
      active
      label
      sortWeight
      status
      statusType
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_NAMES = gql`
  mutation updateServiceRequestNameConfig(
    $clientId: Int!
    $nameConfig: [String]!
  ) {
    updateServiceRequestNameConfig(clientId: $clientId, nameConfig: $nameConfig)
  }
`;
